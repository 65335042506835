import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Amb aquest llibre tens el complement perfecte del primer volum{" "}
                    <em>
                        “Método A. Las 500 preguntas más repetidas en los exámenes de Policía Local”
                    </em>
                    .
                </p>

                <p>
                    500 noves preguntes de cultura general d'exàmens de municipis de tota Catalunya,
                    amb el qual ampliaràs els teus coneixements i assoliràs el nivell cultural
                    necessari per aconseguir la teva plaça.
                </p>

                <p>Cinc-centes preguntes d'exàmens oficials.</p>

                <p>Una única resposta a cada pregunta.</p>

                <p>Ampliació d'informació a les preguntes més rellevants.</p>

                <p>Accés durant un any al mòdul específic del llibre a l'app iGuB.</p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Amb 500 noves preguntes d'exàmens oficials, aquest llibre et permetrà expandir els teus coneixements i preparar-te millor per aconseguir la teva plaça en la Policia Local. Complementa la teva formació amb l'app iGuB i supera les proves amb èxit!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
